import { useState, useEffect, useCallback, useRef } from "react";
import Swal from "sweetalert2";
import api from "../config/api";

export const useOrdersApi = (
    initialPage = 1,
    originalOrderData,
    orderData,
    setOrderData,
    setShowModalEditAdd,
    sort = { sortBy: "createdAt", order: "desc" },
    searchValue = '',
    statusValue = 'all',
    limit = 20,
    attachOrder,
    setShowModalAttach,
    setAttachOrderData
) => {
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [events, setEvents] = useState([]);
    const [dataInfo, setDataInfo] = useState([]);
    const [page, setPage] = useState(initialPage);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const initialPageRef = useRef(initialPage);

    const startSubmitProcess = () => {
        setError(false);
        setErrorMessage("");
        setLoading(!loading);
    };

    const handleInputChange = async (event) => {
        const { name, value } = event.target;

        if (name === "status") {
            setOrderData((prevEvent) => ({
                ...prevEvent,
                [name]: value,
            }));
        } else {
            setOrderData((prevOrder) => ({
                ...prevOrder,
                classes: {
                    ...(prevOrder.classes || {}),
                    [name]: value,
                },
            }));
        }
    };

    const getChangedFields = (original, updated) => {
        const changes = {};
        for (const key in original) {
            if (original[key] !== updated[key]) {
                changes[key] = updated[key];
            }
        }
        return changes;
    };

    const fetchUsers = useCallback(
        async () => {
            setLoading(true);
            try {
                const response = await api.get(
                    `/users?sortBy=fullName&order=asc&status=${statusValue}`
                );
                setUsers(response.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
        [setLoading, statusValue]
    );

    const fetchEvents = useCallback(
        async () => {
            setLoading(true);
            try {
                const response = await api.get(
                    `/events?sortBy=eventName&order=asc&status=${statusValue}`
                );
                setEvents(response.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
        [setLoading, statusValue]
    );

    const fetchData = useCallback(
        async (pageNumber = initialPageRef.current) => {
            setLoading(true);
            try {
                const response = await api.get(
                    `/orders?paginate=true&page=${pageNumber}&sortBy=${sort.sortBy}&order=${sort.order}&search=${searchValue}&status=${statusValue}&limit=${limit}`
                );
                const { docs, ...info } = response.data;
                setData(docs);
                setDataInfo(info);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
        [sort, setLoading, searchValue, statusValue, limit]
    );

    const handleSubmit = async (event) => {
        event.preventDefault();
        startSubmitProcess();
        const changes = getChangedFields(originalOrderData, orderData);

        await api
            .put(`/orders/${orderData._id}`, changes)
            .then(() => {
                setLoading(false);
                setError(false);
                setShowModalEditAdd(false);
                fetchData();
                Swal.fire({
                    icon: "success",
                    title: "Sucesso!",
                    text: "Pedido editado com sucesso.",
                });
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
                setErrorMessage(err.response.data.error);
            });
    };

    const newOrder = async (event) => {
        event.preventDefault();
        startSubmitProcess();
        
        attachOrder.attachingUser = true;

        await api
            .post(`/orders`, attachOrder)
            .then(() => {
                setLoading(false);
                setError(false);
                setShowModalAttach(false);
                setAttachOrderData({});
                fetchData();
                Swal.fire({
                    icon: "success",
                    title: "Sucesso!",
                    text: "Pedido criado com sucesso.",
                });
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
                setErrorMessage(err.response.data.error);
            });
    };

    useEffect(() => {
        fetchData(page);
        fetchUsers();
        fetchEvents();
    }, [fetchData, fetchUsers, fetchEvents, page]);

    const nextPage = () => {
        if (page < dataInfo.totalPages) {
            setPage(page + 1);
        }
    };

    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const exportData = async () => {
        setLoading(true);
        await api
            .get("/export/orders", {
                responseType: "blob",
            })
            .then((res) => {
                setLoading(false);
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "pedidos.csv");
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Erro!",
                    text: `Erro ao exportar dados, tente novamente mais tarde.`,
                });
            });
    };

    return {
        data,
        dataInfo,
        page,
        loading,
        handleSubmit,
        newOrder,
        exportData,
        handleInputChange,
        error,
        errorMessage,
        setError,
        setErrorMessage,
        nextPage,
        prevPage,
        users,
        events
    };
};
