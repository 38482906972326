import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import MaterialIcon from "material-icons-react";
import {
    CardList,
    CardListHeader,
    CardListHeaderItem,
    CardListBody,
    CardListBodyItem,
    CardListBodyImage,
} from "../../../../components/CardList";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import api from "../../../../config/api.js";
import { dateFormat, formatBrazilianAddress } from "../../../../config/utils";

function MyEventsUsers() {
    const [load, setLoad] = useState(false);
    // const [error, setError] = useState(false);
    // const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
    // const [errorMessage, setErrorMessage] = useState("");
    const [dataInfo, setDataInfo] = useState([]);
    const [data, setData] = useState([]);
    const [order, setOrder] = useState([]);
    // const [originalOrder, setOriginalOrder] = useState({});
    const [page, setPage] = useState(1);
    const [showModalView, setShowModalView] = useState(false);
    // const [showModalEdit, setShowModalEdit] = useState(false);

    useEffect(() => {
        document.title = "JS Studio de Dança - Meus Eventos";
        getData();
    }, []);

    const statusClassMap = {
        pending: "text-warning",
        approved: "text-success",
        declined: "text-danger",
        refunded: "text-warning",
        cancelled: "text-danger",
    };

    const statusTranslations = {
        pending: "Pendente",
        approved: "Aprovado",
        declined: "Recusado",
        refunded: "Reembolsado",
        cancelled: "Cancelado",
    };

    // const startSubmitProcess = () => {
    //     setError(false);
    //     setErrorMessage("");
    //     setLoad(!load);
    // };

    // const handleInputChange = async (event) => {
    //     const { name, value } = event.target;
    //     setOrder((prevOrder) => ({
    //         ...prevOrder,
    //         classes: {
    //             ...(prevOrder.classes || {}),
    //             [name]: value,
    //         },
    //     }));
    // };

    // const getChangedFields = (original, updated) => {
    //     const changes = {};
    //     for (const key in original) {
    //         if (original[key] !== updated[key]) {
    //             changes[key] = updated[key];
    //         }
    //     }
    //     return changes;
    // };

    const prevPage = () => {
        if (page === 1) return;
        const pageNumber = page - 1;
        setPage(pageNumber);
        getData(pageNumber);
    };

    const nextPage = () => {
        if (page === dataInfo.pages) return;
        const pageNumber = page + 1;
        setPage(pageNumber);
        getData(pageNumber);
    };

    const availableClasses = [];
    if (order.classes) {
        const classKeys = Object.keys(order.classes);
        for (let i = 0; i < classKeys.length; i += 3) {
            availableClasses.push({
                name: order.classes[classKeys[i]],
                dates: order.classes[classKeys[i + 1]],
                hour: order.classes[classKeys[i + 2]],
            });
        }
    }

    const getData = async (page = 1) => {
        setLoad(true);
        await api
            .get(`/my-orders?paginate=true&page=${page}`)
            .then((response) => {
                const { docs, ...dataInfo } = response.data;
                setData(docs);
                setDataInfo(dataInfo);
                setLoad(false);
            })
            .catch((error) => {
                setLoad(false);
                console.log(error);
            });
    };

    // const submitOrder = async (event) => {
    //     event.preventDefault();

    //     if (!hasAcceptedTerms) {
    //         setError(true);
    //         setErrorMessage(
    //             "Você deve aceitar os termos e condições para se registrar."
    //         );
    //         return;
    //     }

    //     startSubmitProcess();
    //     const changes = getChangedFields(originalOrder, order);

    //     await api
    //         .put(`/orders/${order._id}`, changes)
    //         .then(() => {
    //             setLoad(false);
    //             setError(false);
    //             setShowModalEdit(false);
    //             getData(page);
    //             Swal.fire({
    //                 icon: "success",
    //                 title: "Sucesso!",
    //                 text: "Modalidades editadas com sucesso.",
    //             });
    //         })
    //         .catch((err) => {
    //             setLoad(false);
    //             setError(true);
    //             setErrorMessage(err.response.data.error);
    //         });
    // };

    return (
        <>
            <Sidebar
                pageName="Meus Eventos"
                pageUrl="/dashboard/eventos-usuarios"
            >
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <CardList
                                page={parseInt(dataInfo.page)}
                                pages={dataInfo.pages}
                                callbackNext={nextPage}
                                callbackPrev={prevPage}
                                countItems={dataInfo.total}
                            >
                                <CardListHeader className="bg-color-light-gray">
                                    <Row>
                                        <CardListHeaderItem xs={12} lg={2}>
                                            Banner
                                        </CardListHeaderItem>
                                        <CardListHeaderItem xs={12} lg={4}>
                                            Nome
                                        </CardListHeaderItem>
                                        <CardListHeaderItem xs={12} lg={2}>
                                            Data e Horário
                                        </CardListHeaderItem>
                                        <CardListHeaderItem xs={12} lg={2}>
                                            Informações
                                        </CardListHeaderItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={2}
                                            className="text-center"
                                        >
                                            Status
                                        </CardListHeaderItem>
                                    </Row>
                                </CardListHeader>

                                {data.map((data, index) => (
                                    <CardListBody key={index}>
                                        <Row>
                                            <CardListBodyImage
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center"
                                                image={data.bannerUrl}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={4}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Nome do Evento:"}
                                                value={
                                                    data.event &&
                                                    data.event.eventName
                                                }
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Data e Horário:"}
                                                value={`${dateFormat(
                                                    data.event &&
                                                        data.event.date
                                                )}, às ${
                                                    data.event &&
                                                    data.event.time
                                                }`}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center text-center link-card-list text-muted small"
                                                title={"Tipo:"}
                                                value={
                                                    <>
                                                        <strong
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setShowModalView(
                                                                    true
                                                                );
                                                                setOrder(data);
                                                            }}
                                                        >
                                                            Clique para ver
                                                        </strong>
                                                    </>
                                                }
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className={`d-inline-flex align-items-center justify-content-center small font-semi-bold ${
                                                    statusClassMap[
                                                        data.status
                                                    ] || "text-muted"
                                                }`}
                                                title={"Status:"}
                                                value={
                                                    statusTranslations[
                                                        data.status
                                                    ] || data.status
                                                }
                                            />
                                        </Row>
                                    </CardListBody>
                                ))}
                                {data.length === 0 ? (
                                    <>
                                        <CardListBody>
                                            <Col
                                                xs={12}
                                                className="d-inline-flex align-items-center text-muted small justify-content-center"
                                            >
                                                Nenhum item encontrado.
                                            </Col>
                                        </CardListBody>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </CardList>
                        </Col>
                    </Row>
                </Container>
            </Sidebar>
            <Modal
                show={showModalView}
                onHide={() => {
                    setShowModalView(false);
                    setOrder({});
                }}
                size={"lg"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                {order.event && order.event.eventName}
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => {
                                setShowModalView(false);
                                setOrder({});
                            }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <h4 className="text-center mb-2 font-semi-bold text-uppercase">
                            Dados do Evento
                        </h4>
                        <Row className="mb-2">
                            <Col xs={12} sm={6}>
                                <h6 className="fw-bold">Nome</h6>
                                <p className="text-muted">
                                    {order.event && order.event.eventName}
                                </p>
                            </Col>
                            <Col xs={12} sm={6}>
                                <h6 className="fw-bold">Descrição</h6>
                                <p className="text-muted">
                                    {order.event && order.event.description}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={3}>
                                <h6 className="fw-bold">Data</h6>
                                <p className="text-muted">
                                    {dateFormat(
                                        order.event && order.event.date
                                    )}
                                </p>
                            </Col>
                            <Col xs={12} sm={3}>
                                <h6 className="fw-bold">Horário</h6>
                                <p className="text-muted">
                                    {order.event && order.event.time}
                                </p>
                            </Col>
                            <Col xs={12} sm={3}>
                                <h6 className="fw-bold">Faixa Etária</h6>
                                <p className="text-muted">
                                    de {order.event && order.event.ageRangeMin}{" "}
                                    anos, até{" "}
                                    {order.event && order.event.ageRangeMax}
                                </p>
                            </Col>
                            <Col xs={12} sm={3}>
                                <h6 className="fw-bold">Duração</h6>
                                <p className="text-muted">
                                    {order.event && order.event.duration}
                                </p>
                            </Col>
                        </Row>
                        <h4 className="text-center mt-5 mb-2 font-semi-bold text-uppercase ">
                            Endereço
                        </h4>
                        <Row>
                            <Col xs={12} sm={12}>
                                <a
                                    href={order.event && order.event.linkMaps}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <h6 className="text-center font-semi-bold">
                                        {formatBrazilianAddress(
                                            order.event && order.event.address
                                        )}
                                    </h6>
                                </a>
                            </Col>
                        </Row>
                        {order.event && order.event.typeOfEvent === "show" && (
                            <>
                                <h4 className="text-center mt-5 font-semi-bold text-uppercase p-0">
                                    Modalidades Informadas <br />
                                </h4>
                                {/* <h6
                                    className="text-center mb-3 font-bold text-uppercase color-dark-yellow c-pointer link-card-list"
                                    onClick={() => {
                                        setShowModalView(false);
                                        setShowModalEdit(true);
                                        setOriginalOrder(order);
                                    }}
                                >
                                    alterar modalidades
                                </h6> */}
                                {availableClasses.map((classInfo, index) => (
                                    <Row className="mb-4" key={index}>
                                        <Col xs={12} md={4}>
                                            <h6 className="fw-bold">
                                                Nome da Modalidade
                                            </h6>
                                            <p className="text-muted">
                                                {classInfo.name}
                                            </p>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <h6 className="fw-bold">Datas</h6>
                                            <p className="text-muted">
                                                {classInfo.dates}
                                            </p>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <h6 className="fw-bold">
                                                Horários
                                            </h6>
                                            <p className="text-muted">
                                                {classInfo.hour}
                                            </p>
                                        </Col>
                                    </Row>
                                ))}
                            </>
                        )}
                        <Row className="mt-5 mb-2">
                            <Col xs={12} className="text-center">
                                <p>
                                    Caso não tenha concluído o pagamento, {" "}
                                    <a
                                        href={order.paymentLink}
                                        className="font-semi-bold"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        clique aqui {" "}
                                    </a>
                                    e faça!
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            {/* <Modal
                show={showModalEdit}
                onHide={() => {
                    setShowModalEdit(false);
                    setHasAcceptedTerms(false);
                    setOrder({});
                }}
                size={"lg"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                {order.event && order.event.eventName} - Editar Modalidades
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => {
                                setShowModalEdit(false);
                                setHasAcceptedTerms(false);
                                setOrder({});
                            }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row className="mb-2">
                            <Col xs={12}>
                                <Form onSubmit={submitOrder}>
                                    <div className="mb-4">
                                        <h3 className="mb-4 font-semi-bold text-uppercase">
                                            Quais turmas <br />
                                            você dançará?
                                        </h3>
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={1}
                                                className="d-flex align-items-end justify-content-start ps-3"
                                            >
                                                <h1 className="font-extra-bold">
                                                    1.
                                                    <sup className="ms-1 text-danger fw-bold">
                                                        *
                                                    </sup>
                                                </h1>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Modalidade
                                                        <sup className="ms-1 text-danger fw-bold">
                                                            *
                                                        </sup>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="firstClassName"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.firstClassName}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Dias
                                                        <sup className="ms-1 text-danger fw-bold">
                                                            *
                                                        </sup>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="firstClassDates"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.firstClassDates}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Horário
                                                        <sup className="ms-1 text-danger fw-bold">
                                                            *
                                                        </sup>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="firstClassHour"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.firstClassHour}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={1}
                                                className="d-flex align-items-end justify-content-start ps-3"
                                            >
                                                <h1 className="font-extra-bold">
                                                    2.
                                                </h1>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Modalidade
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="secondClassName"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.secondClassName}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Dias
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="secondClassDates"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.secondClassDates}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Horário
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="secondClassHour"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.secondClassHour}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={1}
                                                className="d-flex align-items-end justify-content-start ps-3"
                                            >
                                                <h1 className="font-extra-bold">
                                                    3.
                                                </h1>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Modalidade
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="thirdClassName"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.thirdClassName}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Dias
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="thirdClassDates"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.thirdClassDates}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Horário
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="thirdClassHour"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.thirdClassHour}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={1}
                                                className="d-flex align-items-end justify-content-start ps-3"
                                            >
                                                <h1 className="font-extra-bold">
                                                    4.
                                                </h1>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Modalidade
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="fourthClassName"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.fourthClassName}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Dias
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="fourthClassDates"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.fourthClassDates}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Group className="mb-3 mb-md-2">
                                                    <Form.Label className="text-uppercase">
                                                        Horário
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="fourthClassHour"
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={order.classes && order.classes.fourthClassHour}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <small className="color-light-gray">
                                                Os campos marcados com um
                                                <strong className="ms-1 text-danger fw-bold">
                                                    *
                                                </strong>{" "}
                                                são de preenchimento
                                                obrigatório. Não se esqueça
                                                de preenchê-los.
                                                <br />
                                                Certifique-se de revisar e
                                                confirmar as informações
                                                inseridas no formulário a
                                                cima, garantindo que estejam
                                                corretas e verdadeiras.
                                            </small>
                                        </Row>
                                    </div>
                                    <Row className="mb-0 mb-md-3">
                                        <Col
                                            xs={12}
                                            className="d-flex justify-content-start mt-4"
                                        >
                                            <Form.Group className="mb-2">
                                                <Form.Check
                                                    type="checkbox"
                                                    label={
                                                        <span>
                                                            Ao marcar esta
                                                            opção, você concorda
                                                            com os{" "}
                                                            <a
                                                                href="https://www.jsstudiodedanca.com.br/inscricao-4-mostra-js-studio-de-danca/js_studio_eventos_termos_de_uso_e_politica_de_privacidade.pdf"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <strong>
                                                                    Termos &
                                                                    Condições
                                                                </strong>
                                                            </a>{" "}
                                                            do evento.
                                                        </span>
                                                    }
                                                    onChange={(e) =>
                                                        setHasAcceptedTerms(
                                                            e.target.checked
                                                        )
                                                    }
                                                    checked={hasAcceptedTerms}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col xs={12} className="d-flex justify-content-center" >
                                            <ButtonCallToACtion
                                                btnText={"CONFIRMAR MINHAS TURMAS"}
                                                className={"big-button payment-button"}
                                                type="submit"
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {error && (
                                    <div className="d-flex justify-content-center">
                                        <Alert
                                            variant={"danger"}
                                            className="mb-0 text-center small w-auto"
                                        >
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal> */}
            <Loading show={load} />
        </>
    );
}

export default MyEventsUsers;
