import React, { useState, useEffect, useCallback } from "react";
import {
    Container,
    Row,
    Col,
    Dropdown,
    Modal,
    Form,
    Alert,
    Button,
} from "react-bootstrap";
import Sidebar from "../../../components/Sidebar";
import MaterialIcon from "material-icons-react";
import {
    CardList,
    CardListHeader,
    CardListHeaderItem,
    CardListHeaderSortItem,
    CardListBody,
    CardListBodyItem,
    CardListBodyItemOptions,
} from "../../../components/CardList";
import { ButtonPrimary } from "../../../components/Buttons";
import Loading from "../../../components/Loading";
import {
    dateHourFormat,
    dateFormat,
    formatPrice,
    isUserMinor,
    debounce,
} from "../../../config/utils";
import { useOrdersApi } from "../../../hooks/useOrdersApi";

function Orders() {
    const [originalOrderData, setOriginalOrderData] = useState({});
    const [orderData, setOrderData] = useState({});
    const [attachOrderData, setAttachOrderData] = useState({});
    const [attachOrderPrices, setAttachOrderPrices] = useState({});
    const [showModalView, setShowModalView] = useState(false);
    const [showModalEditAdd, setShowModalEditAdd] = useState(false);
    const [showModalAttach, setShowModalAttach] = useState(false);
    const [sort, setSort] = useState({ sortBy: "createdAt", order: "desc" });
    const [searchValue, setSearchValue] = useState("");
    const [statusValue, setStatusValue] = useState("all");
    const [limitValue, setLimitValue] = useState(20);

    const {
        data,
        dataInfo,
        loading,
        nextPage,
        prevPage,
        users,
        events,
        error,
        errorMessage,
        setError,
        setErrorMessage,
        handleInputChange,
        handleSubmit,
        newOrder,
        exportData,
    } = useOrdersApi(
        1,
        originalOrderData,
        orderData,
        setOrderData,
        setShowModalEditAdd,
        sort,
        searchValue,
        statusValue,
        limitValue,
        attachOrderData,
        setShowModalAttach,
        setAttachOrderData
    );

    useEffect(() => {
        document.title = "JS Studio de Dança - Pedidos";
    }, []);

    const handleModalClose = () => {
        setShowModalEditAdd(false);
        setShowModalAttach(false);
        setOrderData({});
        setAttachOrderData({});
        setError(false);
        setErrorMessage("");
    };

    const statusClassMap = {
        pending: "text-warning",
        approved: "text-success",
        declined: "text-danger",
        refunded: "text-warning",
        cancelled: "text-danger",
    };

    const statusTranslations = {
        pending: "Pendente",
        approved: "Aprovado",
        declined: "Recusado",
        refunded: "Reembolsado",
        cancelled: "Cancelado",
    };

    const keyTranslations = {
        fullPrice: "Inteira",
        halfEntry: "Meia-entrada",
        advancePrice: "Pagamento Antecipado",
        promotionalPrice: "Preço Promocional",
    };

    const userLabels = {
        fullName: "Nome",
        email: "E-mail",
        cpf: "CPF",
        gender: "Gênero",
        birthDate: "Data de Nascimento",
        cellphone: "Celular",
        phone: "Telefone",
        role: "Tipo de Usuário",
        status: "Status",
    };

    const eventLabels = {
        eventName: "Nome do Evento",
        description: "Descrição",
        ageRange: "Faixa Etária",
        date: "Data",
        time: "Hora",
        duration: "Duração",
        linkMaps: "Link do Google Maps",
        status: "Status",
    };

    const handleEventSelection = (selectedEventId) => {
        const selectedEvent = events.find(event => event._id === selectedEventId);
    
        if (selectedEvent && selectedEvent.prices) {
            setAttachOrderPrices(selectedEvent.prices);
        }
    };

    const renderPriceOptions = () => {
        const priceOptions = [];
        const priceLabels = {
            fullPrice: "Inteira",
            halfEntry: "Meia-entrada",
            advancePrice: "Valor adiantado",
            promotionalPrice: "Valor promocional"
        };
    
        for (const key in attachOrderPrices) {
            if (!key.endsWith('Link') && attachOrderPrices[key] != null) {
                priceOptions.push(
                    <option key={key} value={key}>
                        {priceLabels[key]}
                    </option>
                );
            }
        }
    
        return priceOptions;
    };

    const availableClasses = [];
    if (orderData.classes) {
        const classKeys = Object.keys(orderData.classes);
        for (let i = 0; i < classKeys.length; i += 3) {
            availableClasses.push({
                name: orderData.classes[classKeys[i]],
                dates: orderData.classes[classKeys[i + 1]],
                hour: orderData.classes[classKeys[i + 2]],
            });
        }
    }

    if (orderData.event) {
        orderData.event.ageRange = `de ${orderData.event.ageRangeMin} anos, até ${orderData.event.ageRangeMax}`;
    }

    const handleSort = (sortKey) => {
        setSort((prevSort) => {
            const isAsc =
                prevSort.sortBy === sortKey && prevSort.order === "asc";
            return {
                sortBy: sortKey,
                order: isAsc ? "desc" : "asc",
            };
        });
    };

    const handleMobileSort = (value) => {
        const [sortBy, order] = value.split("-");
        setSort({
            sortBy: sortBy.trim(),
            order: order.trim(),
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetSearchValue = useCallback(
        debounce(setSearchValue, 20),
        []
    );

    const handleInputSearch = (event) => {
        const { name, value } = event.target;

        if (name === "search") {
            debouncedSetSearchValue(value);
        }
        if (name === "status") {
            setStatusValue(value);
        }
        if (name === "limit") {
            setLimitValue(value);
        }
    };

    const handleAttachOrderChange = (event) => {
        const { name, value } = event.target;

        setAttachOrderData((prevOrder) => ({
            ...prevOrder,
            classes: {
                ...(prevOrder.classes || {}),
                [name]: value,
            },
        }));
    };

    return (
        <>
            <Sidebar pageName="Pedidos" pageUrl="/dashboard/pedidos">
                <Container fluid>
                    <Row className="m-2 justify-content-end">
                        <Col xs={12} sm={3} className="text-right">
                            <small>
                                Pesquisar por nome, CPF, email ou celular
                            </small>
                            <input
                                type="text"
                                className="form-control input-search"
                                name="search"
                                value={searchValue}
                                onChange={handleInputSearch}
                            />
                        </Col>
                        <Col xs={12} sm={2} className="text-right">
                            <small>Filtrar por status</small>
                            <select
                                className="form-control input-search"
                                name="status"
                                value={statusValue}
                                onChange={handleInputSearch}
                            >
                                <option value="" disabled>
                                    Filtrar por status
                                </option>
                                {[
                                    {
                                        name: "Todos",
                                        value: "all",
                                    },
                                    {
                                        name: "Pendente",
                                        value: "pending",
                                    },
                                    {
                                        name: "Aprovado",
                                        value: "approved",
                                    },
                                    {
                                        name: "Recusado",
                                        value: "declined",
                                    },
                                    {
                                        name: "Reembolsado",
                                        value: "refunded",
                                    },
                                    {
                                        name: "Cancelado",
                                        value: "cancelled",
                                    },
                                ].map((item, index) => (
                                    <option value={item.value} key={index}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col xs={12} sm={1}>
                            <small># itens</small>
                            <select
                                className="form-control input-search"
                                name="limit"
                                value={limitValue}
                                onChange={handleInputSearch}
                            >
                                <option value="" disabled>
                                    Selecionar limite
                                </option>
                                {[
                                    {
                                        name: "5",
                                        value: 5,
                                    },
                                    {
                                        name: "10",
                                        value: 10,
                                    },
                                    {
                                        name: "20",
                                        value: 20,
                                    },
                                    {
                                        name: "40",
                                        value: 40,
                                    },
                                    {
                                        name: "80",
                                        value: 80,
                                    },
                                    {
                                        name: "100",
                                        value: 100,
                                    },
                                ].map((item, index) => (
                                    <option value={item.value} key={index}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col
                            xs={8}
                            sm={2}
                            className="d-flex align-items-end justify-content-end"
                        >
                            <Button
                                className="custom-btn btn-app btt-add"
                                onClick={() => {
                                    setShowModalAttach(true);
                                }}
                            >
                                atrelar pedido
                            </Button>
                        </Col>
                        <Col
                            xs={4}
                            sm={1}
                            className="d-flex align-items-end justify-content-end"
                        >
                            <Button
                                className="custom-btn btn-app btt-export"
                                onClick={() => exportData()}
                            >
                                <MaterialIcon icon="file_download" />
                            </Button>
                        </Col>
                    </Row>
                    <Row className="d-flex d-md-none mx-2 mt-3 mb-2">
                        <Col xs={12} className="text-right">
                            <label>Ordenar por</label>
                            <select
                                className="form-control input-search"
                                name="select"
                                value={`${sort.sortBy} - ${sort.order}`}
                                onChange={(e) =>
                                    handleMobileSort(e.target.value)
                                }
                            >
                                <option value="user.fullName - asc">
                                    Nome - Crescente
                                </option>
                                <option value="user.fullName - desc">
                                    Nome - Decrescente
                                </option>
                                <option value="createdAt - asc">
                                    Data de Inscrição - Crescente
                                </option>
                                <option value="createdAt - desc">
                                    Data de Inscrição - Decrescente
                                </option>
                                <option value="status - asc">
                                    Status - Crescente
                                </option>
                                <option value="status - desc">
                                    Status - Decrescente
                                </option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CardList
                                page={parseInt(dataInfo.page)}
                                pages={dataInfo.totalPages}
                                data={data}
                                callbackNext={nextPage}
                                callbackPrev={prevPage}
                            >
                                <CardListHeader className="bg-color-light-gray">
                                    <Row>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"user.fullName"}
                                        >
                                            Usuário
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"event.eventName"}
                                        >
                                            Evento
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={2}
                                            onSort={handleSort}
                                            sortKey={"createdAt"}
                                        >
                                            Data de Inscrição
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={2}
                                            className="text-center"
                                            onSort={handleSort}
                                            sortKey={"status"}
                                        >
                                            Status
                                        </CardListHeaderSortItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={2}
                                            className="text-center"
                                        >
                                            Gerenciar
                                        </CardListHeaderItem>
                                    </Row>
                                </CardListHeader>

                                {data.map((data, index) => (
                                    <CardListBody key={index}>
                                        <Row>
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Nome:"}
                                                value={
                                                    data.user &&
                                                    data.user.fullName
                                                }
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Nome do Evento:"}
                                                value={
                                                    data.event &&
                                                    data.event.eventName
                                                }
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Data de Inscrição:"}
                                                value={dateHourFormat(
                                                    data.createdAt
                                                )}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className={`d-inline-flex small font-semi-bold ${
                                                    statusClassMap[
                                                        data.status
                                                    ] || "text-muted"
                                                }`}
                                                title={"Status:"}
                                                value={
                                                    statusTranslations[
                                                        data.status
                                                    ] || data.status
                                                }
                                            />
                                            <CardListBodyItemOptions
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center justify-content-center"
                                            >
                                                <Dropdown.Item
                                                    className="text-success font-semi-bold text-center"
                                                    onClick={() => {
                                                        setShowModalView(true);
                                                        setOrderData(data);
                                                    }}
                                                >
                                                    Ver informações
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="text-primary font-semi-bold text-center"
                                                    onClick={() => {
                                                        setShowModalEditAdd(
                                                            true
                                                        );
                                                        setOrderData(data);
                                                        setOriginalOrderData(
                                                            data
                                                        );
                                                    }}
                                                >
                                                    Editar informações
                                                </Dropdown.Item>
                                            </CardListBodyItemOptions>
                                        </Row>
                                    </CardListBody>
                                ))}
                                {data.length === 0 ? (
                                    <>
                                        <CardListBody>
                                            <Col
                                                xs={12}
                                                className="d-inline-flex align-items-center text-muted small justify-content-center"
                                            >
                                                Nenhum item encontrado.
                                            </Col>
                                        </CardListBody>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </CardList>
                        </Col>
                    </Row>
                </Container>
            </Sidebar>
            <Modal
                show={showModalView}
                onHide={() => {
                    setShowModalView(false);
                    setOrderData({});
                }}
                size={"lg"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                {`${
                                    orderData.user && orderData.user.fullName
                                } - ${
                                    orderData.event && orderData.event.eventName
                                }`}
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => {
                                setShowModalView(false);
                                setOrderData({});
                            }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                            Dados do Usuário
                        </h4>
                        {orderData.user && (
                            <>
                                <Row className="mb-2">
                                    {Object.entries(orderData.user)
                                        .filter(([key]) => userLabels[key])
                                        .map(([key, value]) => (
                                            <Col xs={12} sm={4} key={key}>
                                                <h6 className="fw-bold">
                                                    {userLabels[key]}{" "}
                                                </h6>
                                                <p
                                                    className={`${
                                                        (key === "status" &&
                                                            value ===
                                                                "active" &&
                                                            "fw-bold text-success") ||
                                                        (value === "inactive" &&
                                                            "fw-bold text-danger") ||
                                                        (value ===
                                                            "suspended" &&
                                                            "fw-bold text-warning")
                                                    }`}
                                                >
                                                    {key === "birthDate"
                                                        ? dateFormat(value)
                                                        : key === "status"
                                                        ? (value === "active" &&
                                                              "Ativo") ||
                                                          (value ===
                                                              "inactive" &&
                                                              "Inativo") ||
                                                          (value ===
                                                              "suspended" &&
                                                              "Suspenso")
                                                        : key === "role"
                                                        ? (value ===
                                                              "student" &&
                                                              "Estudante") ||
                                                          (value ===
                                                              "guardian" &&
                                                              "Responsável") ||
                                                          (value === "both" &&
                                                              "Ambos")
                                                        : value === undefined ||
                                                          value === "" ||
                                                          value === null
                                                        ? "Não informado"
                                                        : value}
                                                </p>
                                            </Col>
                                        ))}
                                </Row>
                                {isUserMinor(orderData.user.birthDate) && (
                                    <>
                                        <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                                            Dados do Responsável
                                        </h4>
                                        <Row className="mb-2">
                                            <Col xs={12} md={6}>
                                                <h6 className="fw-bold">
                                                    Nome - Responsável
                                                </h6>
                                                <p className="text-muted">
                                                    {orderData.user.guardianName
                                                        ? orderData.user
                                                              .guardianName
                                                        : "Não informado"}
                                                </p>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <h6 className="fw-bold">
                                                    Telefone - Responsável
                                                </h6>
                                                <p className="text-muted">
                                                    {orderData.user
                                                        .guardianPhone
                                                        ? orderData.user
                                                              .guardianPhone
                                                        : "Não informado"}
                                                </p>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </>
                        )}
                        <h4 className="text-center font-semi-bold mb-4 text-uppercase">
                            Dados do Evento
                        </h4>
                        {orderData.event && (
                            <Row className="mb-2">
                                {Object.entries(orderData.event)
                                    .filter(([key]) => eventLabels[key])
                                    .map(([key, value]) => (
                                        <Col xs={12} sm={4} key={key}>
                                            <h6 className="fw-bold">
                                                {eventLabels[key]}
                                            </h6>
                                            <p
                                                className={`text-break ${
                                                    key === "status"
                                                        ? value === "active"
                                                            ? "fw-bold text-success"
                                                            : value ===
                                                              "inactive"
                                                            ? "fw-bold text-danger"
                                                            : "fw-bold text-warning"
                                                        : ""
                                                }`}
                                            >
                                                {key === "date" ? (
                                                    dateFormat(value)
                                                ) : key === "status" ? (
                                                    value === "active" ? (
                                                        "Ativo"
                                                    ) : value === "inactive" ? (
                                                        "Inativo"
                                                    ) : (
                                                        "Suspenso"
                                                    )
                                                ) : key === "linkMaps" ? (
                                                    <a
                                                        className="font-semi-bold"
                                                        href={value}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Clique aqui
                                                    </a>
                                                ) : (
                                                    value.toString()
                                                )}
                                            </p>
                                        </Col>
                                    ))}
                            </Row>
                        )}
                        {orderData.event && orderData.event.prices && (
                            <Row className="mb-2">
                                {(() => {
                                    // Obter as entradas válidas de preços
                                    const priceEntries = Object.entries(
                                        orderData.event.prices
                                    ).filter(
                                        ([key, value]) =>
                                            keyTranslations[key] &&
                                            value != null
                                    );

                                    // Calcular o tamanho da coluna com base no número de entradas de preços
                                    const colSize = 12 / priceEntries.length;

                                    return priceEntries.map(([key, value]) => (
                                        <Col
                                            xs={12}
                                            sm={colSize} // tamanho dinâmico da coluna
                                            key={key}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <div className="text-center">
                                                <h6 className="fw-bold text-capitalize">
                                                    {keyTranslations[key]}
                                                </h6>
                                                <p className="text-muted">
                                                    {formatPrice(value)}
                                                </p>
                                            </div>
                                        </Col>
                                    ));
                                })()}
                            </Row>
                        )}
                        <h4 className="text-center font-semi-bold mb-4 text-uppercase">
                            Dados do Pedido
                        </h4>
                        <Row className="mb-2">
                            <Col xs={12} sm={12}>
                                {orderData.event &&
                                orderData.event.typeOfEvent === "show" ? (
                                    availableClasses.map((classInfo, index) => (
                                        <Row className="mb-2" key={index}>
                                            <Col xs={12} md={4}>
                                                <h6 className="fw-bold">
                                                    Nome da Modalidade
                                                </h6>
                                                <p className="text-muted">
                                                    {classInfo.name}
                                                </p>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <h6 className="fw-bold">
                                                    Datas
                                                </h6>
                                                <p className="text-muted">
                                                    {classInfo.dates}
                                                </p>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <h6 className="fw-bold">
                                                    Horários
                                                </h6>
                                                <p className="text-muted">
                                                    {classInfo.hour}
                                                </p>
                                            </Col>
                                        </Row>
                                    ))
                                ) : (
                                    <Row className="mb-2">
                                        <Col xs={12} className="text-center">
                                            <h6>Modalidade Escolhida</h6>
                                            <p className="text-muted">
                                                {
                                                    keyTranslations[
                                                        orderData.type
                                                    ]
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container fluid>
                        <Row className="mb-2">
                            <Col xs={12} md={6}>
                                <small className="text-muted">
                                    Criado em:{" "}
                                    <strong>
                                        {dateHourFormat(orderData.createdAt)}
                                    </strong>
                                    .
                                </small>
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                className="text-start text-md-end"
                            >
                                <small className="text-muted">
                                    Atualizado em:{" "}
                                    <strong>
                                        {dateHourFormat(orderData.updatedAt)}
                                    </strong>
                                    .
                                </small>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showModalEditAdd}
                onHide={() => handleModalClose()}
                size={"lg"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                {`${
                                    orderData.user && orderData.user.fullName
                                } - ${
                                    orderData.event && orderData.event.eventName
                                }`}
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => handleModalClose()}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form onSubmit={handleSubmit}>
                            <h4 className="text-center font-semi-bold mb-4 text-uppercase">
                                Dados do Pedido
                            </h4>
                            {orderData.event &&
                            orderData.event.typeOfEvent === "show" ? (
                                <>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={1}
                                            className="d-flex align-items-end justify-content-center"
                                        >
                                            <h1 className="font-extra-bold">
                                                1.
                                            </h1>
                                        </Col>
                                        <Col xs={12} md={5}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Modalidade
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="firstClassName"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .firstClassName
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Dias
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="firstClassDates"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .firstClassDates
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Horário
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="firstClassHour"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .firstClassHour
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={1}
                                            className="d-flex align-items-end justify-content-center"
                                        >
                                            <h1 className="font-extra-bold">
                                                2.
                                            </h1>
                                        </Col>
                                        <Col xs={12} md={5}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Modalidade
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="secondClassName"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .secondClassName
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Dias
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="secondClassDates"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .secondClassDates
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Horário
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="secondClassHour"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .secondClassHour
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={1}
                                            className="d-flex align-items-end justify-content-center"
                                        >
                                            <h1 className="font-extra-bold">
                                                3.
                                            </h1>
                                        </Col>
                                        <Col xs={12} md={5}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Modalidade
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="thirdClassName"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .thirdClassName
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Dias
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="thirdClassDates"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .thirdClassDates
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Horário
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="thirdClassHour"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .thirdClassHour
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={1}
                                            className="d-flex align-items-end justify-content-center"
                                        >
                                            <h1 className="font-extra-bold">
                                                4.
                                            </h1>
                                        </Col>
                                        <Col xs={12} md={5}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Modalidade
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fourthClassName"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .fourthClassName
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Dias
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fourthClassDates"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .fourthClassDates
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Horário
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fourthClassHour"
                                                    onChange={handleInputChange}
                                                    value={
                                                        orderData.classes &&
                                                        orderData.classes
                                                            .fourthClassHour
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <Row className="mb-2">
                                    <Col xs={12} className="text-center">
                                        <h6>Modalidade Escolhida</h6>
                                        <p className="text-muted">
                                            {keyTranslations[orderData.type]}
                                        </p>
                                    </Col>
                                </Row>
                            )}
                            <Row className="mt-4">
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Status do Pedido
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="status"
                                            onChange={handleInputChange}
                                            value={orderData.status}
                                            required
                                        >
                                            <option value="" disabled>
                                                Selecione o status
                                            </option>
                                            {[
                                                {
                                                    name: "Pendente",
                                                    value: "pending",
                                                },
                                                {
                                                    name: "Aprovado",
                                                    value: "approved",
                                                },
                                                {
                                                    name: "Recusado",
                                                    value: "declined",
                                                },
                                                {
                                                    name: "Reembolsado",
                                                    value: "refunded",
                                                },
                                                {
                                                    name: "Cancelado",
                                                    value: "cancelled",
                                                },
                                            ].map((item, index) => (
                                                <option
                                                    value={item.value}
                                                    key={index}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {error && (
                                    <div className="d-flex justify-content-center">
                                        <Alert
                                            variant={"danger"}
                                            className="mb-0 text-center small w-auto"
                                        >
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    </div>
                                )}
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-center"
                                >
                                    <ButtonPrimary
                                        btnText={"Editar Pedido"}
                                        type="submit"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal
                show={showModalAttach}
                onHide={() => handleModalClose()}
                size={"lg"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                Atrelar pedido
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => handleModalClose()}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form onSubmit={newOrder}>
                            <h4 className="text-center font-semi-bold mb-4 text-uppercase">
                                Dados do Pedido
                            </h4>
                            <Row className="mt-4">
                                <Col xs={12} md={4}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Usuário
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="userId"
                                            value={attachOrderData.userId}
                                            onChange={(event) => {
                                                setAttachOrderData({
                                                    ...attachOrderData,
                                                    userId: event.target.value,
                                                });
                                            }}
                                            required
                                        >
                                            <option value=""></option>
                                            {users.map((user, index) => (
                                                <option
                                                    key={index}
                                                    value={user._id}
                                                >
                                                    {user.fullName}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Evento
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="event"
                                            value={`${attachOrderData.event}::${attachOrderData.typeOfEvent}`}
                                            onChange={(event) => {
                                                const [
                                                    selectedId,
                                                    selectedType,
                                                ] =
                                                    event.target.value.split(
                                                        "::"
                                                    );
                                                setAttachOrderData({
                                                    ...attachOrderData,
                                                    event: selectedId,
                                                    typeOfEvent: selectedType,
                                                    type:
                                                        selectedType === "show"
                                                            ? "fullPrice"
                                                            : "",
                                                });

                                                handleEventSelection(selectedId);
                                            }}
                                            required
                                        >
                                            <option value=""></option>
                                            {events.map((event, index) => (
                                                <option
                                                    key={index}
                                                    value={`${event._id}::${event.typeOfEvent}`}
                                                >
                                                    {event.eventName}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Modalidade
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="prices"
                                            value={attachOrderData.type}
                                            onChange={(event) => {
                                                setAttachOrderData({
                                                    ...attachOrderData,
                                                    type: event.target.value,
                                                });
                                            }}
                                            disabled={
                                                attachOrderData.typeOfEvent ===
                                                "show"
                                            }
                                            required
                                        >
                                            <option value=""></option>
                                            {renderPriceOptions()}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {attachOrderData.typeOfEvent === "show" && (
                                <div className="my-4">
                                    <h4 className="mb-4 font-semi-bold text-uppercase">
                                        Quais turmas <br />o aluno dançará?
                                    </h4>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={1}
                                            className="d-flex align-items-end justify-content-start ps-3"
                                        >
                                            <h1 className="font-extra-bold">
                                                1.
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            </h1>
                                        </Col>
                                        <Col xs={12} md={5}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Modalidade
                                                    <sup className="ms-1 text-danger fw-bold">
                                                        *
                                                    </sup>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="firstClassName"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .firstClassName
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Dias
                                                    <sup className="ms-1 text-danger fw-bold">
                                                        *
                                                    </sup>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="firstClassDates"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .firstClassDates
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Horário
                                                    <sup className="ms-1 text-danger fw-bold">
                                                        *
                                                    </sup>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="firstClassHour"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .firstClassHour
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={1}
                                            className="d-flex align-items-end justify-content-start ps-3"
                                        >
                                            <h1 className="font-extra-bold">
                                                2.
                                            </h1>
                                        </Col>
                                        <Col xs={12} md={5}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Modalidade
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="secondClassName"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .secondClassName
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Dias
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="secondClassDates"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .secondClassDates
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Horário
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="secondClassHour"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .secondClassHour
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={1}
                                            className="d-flex align-items-end justify-content-start ps-3"
                                        >
                                            <h1 className="font-extra-bold">
                                                3.
                                            </h1>
                                        </Col>
                                        <Col xs={12} md={5}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Modalidade
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="thirdClassName"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .thirdClassName
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Dias
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="thirdClassDates"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .thirdClassDates
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Horário
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="thirdClassHour"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .thirdClassHour
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={1}
                                            className="d-flex align-items-end justify-content-start ps-3"
                                        >
                                            <h1 className="font-extra-bold">
                                                4.
                                            </h1>
                                        </Col>
                                        <Col xs={12} md={5}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Modalidade
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fourthClassName"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .fourthClassName
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Dias
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fourthClassDates"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .fourthClassDates
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Horário
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fourthClassHour"
                                                    value={
                                                        attachOrderData.classes &&
                                                        attachOrderData.classes
                                                            .fourthClassHour
                                                    }
                                                    onChange={
                                                        handleAttachOrderChange
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            <Row>
                                {error && (
                                    <div className="d-flex justify-content-center">
                                        <Alert
                                            variant={"danger"}
                                            className="mb-0 text-center small w-auto"
                                        >
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    </div>
                                )}
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-center"
                                >
                                    <ButtonPrimary
                                        btnText={"Atrelar pedido"}
                                        type="submit"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
            <Loading show={loading} />
        </>
    );
}

export default Orders;
